<template>
  <div class="shopping-cart">
    <TextHead :title="'购物车'" />

    <div class="cart-body w1200">
      <div class="empty" v-if="!goodsList || goodsList.length < 1">
        <i class="icon el-icon-shopping-cart-1"></i>
        <div class="text">
          <div>购物车内暂无商品，登录后将显示您之前加入的商品</div>
          <el-button type="text" @click="goLogin">挑选商品 ></el-button>
        </div>
      </div>

      <el-table v-else :data="goodsList" border style="width: 100%">
        <el-table-column
          prop="name"
          label="商品名称"
          min-width="180"
          align="center"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop=""
          label="商品图片"
          min-width="120"
          align="center"
        >
          <template slot-scope="scope">
            <el-image :src="scope.row.img"></el-image>
          </template>
        </el-table-column>
        <el-table-column label="价格" min-width="100" align="center">
          <template slot-scope="scope"> ￥ {{ scope.row.price }} </template>
        </el-table-column>
        <el-table-column prop="" label="数量" min-width="120" align="center">
          <template slot-scope="scope">
            <el-input-number
              v-model="scope.row.num"
              :min="1"
              :max="30"
              label="数量"
              size="small"
              @change="getNum"
            ></el-input-number>
          </template>
        </el-table-column>
        <el-table-column prop="" label="小计" min-width="100" align="center">
          <template slot-scope="scope">
            ￥ {{ (scope.row.price * scope.row.num).toFixed(2) }}
          </template>
        </el-table-column>
        <el-table-column prop="" label="操作" min-width="100" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="removeGoods(scope.row)"
              >移除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="settlement">
      <div class="settlement-body w1200">
        <div class="num">
          实付金额：
          <span class="price"
            >￥ {{ !goodsList || goodsList.length < 1 ? 0 : num }}</span
          >
        </div>
        <el-button type="danger" @click="goLogin">去结算 ></el-button>
      </div>
    </div>

    <MallFooter />
  </div>
</template>

<script>
import TextHead from "@/views/TextHead.vue";
import MallFooter from "@/views/MallFooter.vue";
import storage from "../utils/storage";
export default {
  name: "shoppingCart",
  components: {
    TextHead,
    MallFooter,
  },
  data() {
    return {
      goodsList: storage.get("buyGoods"),
      num: "",
    };
  },
  created() {},
  mounted() {
    this.getNum();
  },
  methods: {
    goLogin() {
      this.$router.push("/login");
    },
    getNum() {
      this.$nextTick(function () {
        let priceList = [];
        document.querySelectorAll(".el-table_1_column_5").forEach((item) => {
          priceList.push(Number(item.firstChild.innerHTML.substring(2)));
        });
        priceList.splice(0, 1);
        this.num = eval(priceList.join("+"));
      });
    },
    removeGoods(row) {
      console.log(row.id);
      let goodsId = [];
      this.goodsList.forEach((item) => {
        goodsId.push(item.id);
      });
      this.goodsList.splice(goodsId.indexOf(row.id), 1);
      storage.set("buyGoods", this.goodsList);
      this.getNum();
    },
  },
};
</script>

<style lang="scss" scoped>
.cart-body {
  width: 1000px;
  border: 1px solid #e6e6e6;
  .empty {
    display: flex;
    justify-content: center;
    padding: 56px 0;
    text-align: center;
    font-size: 12px;
    .icon {
      font-size: 70px;
      color: #cccccc;
    }
    .el-button {
      padding: 0;
      font-size: 12px;
    }
    .text {
      padding-top: 12px;
      padding-left: 20px;
      div {
        margin-bottom: 10px;
      }
    }
  }
  .el-table {
    .el-image {
      width: 80px;
    }
  }
}
.settlement-body {
  width: 1000px;
  display: flex;
  justify-content: flex-end;
  padding-right: 90px;
  margin-top: 20px;
  .el-button {
    padding: 20px 40px;
    font-size: 18px;
  }
  .num {
    line-height: 60px;
    font-size: 16px;
    .price {
      font-size: 20px;
      color: red;
    }
    margin-right: 20px;
  }
}
</style>
